import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import showToast from "../components/Toast";
import { API_BASE_URL } from "../config";
import FileDownload from "js-file-download";
import moment from "moment";

// get all jobs

const getJobs = async (
  token,
  search = "",
  sort = "",
  limit = "",
  page = 1,
  week,
  year,
  syncRemoteJobs = false
) => {
  return await axios.get(
    `${API_BASE_URL}/foreman/jobs?search=${search}&sort=${sort}&limit=${limit}&page=${page}&week=${week}&year=${year}&syncRemoteJobs=${syncRemoteJobs}`,
    {
      headers: {
        authorization: "Bearer " + token,
      },
    }
  );
};

export const useGetJobs = (token, search, sort, limit, page, week, year, syncRemoteJobs) => {
  return useQuery(["jobs", token, search, sort, limit, page, week, year, syncRemoteJobs], () =>
    getJobs(token, search, sort, limit, page, week, year, syncRemoteJobs)
  );
};

//---------------------------------------------------- Get Jobs for worker-------------------------------
// get all jobs

const getWorkerJobs = async (
  token,
  search = "",
  sort = "",
  limit = "",
  page = 1,
  week,
  year
) => {
  return await axios.get(
    `${API_BASE_URL}/worker/jobs?search=${search}&sort=${sort}&limit=${limit}&page=${page}&week=${week}&year=${year}`,
    {
      headers: {
        authorization: "Bearer " + token,
      },
    }
  );
};

export const useGetWorkerJobs = (
  token,
  search,
  sort,
  limit,
  page,
  week,
  year
) => {
  return useQuery(["jobs", token, search, sort, limit, page, week, year], () =>
    getWorkerJobs(token, search, sort, limit, page, week, year)
  );
};

// delete job

export const deleteJob = async (userData) => {
  return axios.delete(`${API_BASE_URL}/foreman/job/${userData.userId}`, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useDeleteJob = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteJob, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("jobs");
      showToast("User deleted Sucessfully!", "success");
    },
  });
};

// -------------------------------------  delete Hour foreman ----------------------

export const deleteHour = async (userData) => {
  return axios.delete(`${API_BASE_URL}/foreman/hour/${userData.hourId}`, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useDeleteHour = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteHour, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("workerHours");
      showToast("Hour detail deleted Sucessfully!", "success");
    },
  });
};

// ------------------------------------- delete budget -------------------------

export const deleteBudget = async (userData) => {
  return axios.delete(`${API_BASE_URL}/foreman/budget/${userData.budgetId}`, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useDeleteBudget = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteBudget, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("job");
      showToast("Budget detail deleted Sucessfully!", "success");
    },
  });
};

//------------------------------------ delete hours worker ----------------

// delete Hour Worker

export const deleteWorkerHour = async (userData) => {
  return axios.delete(`${API_BASE_URL}/worker/hour/${userData.hourId}`, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useDeleteWorkerHour = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteWorkerHour, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("job");
      showToast("Hour detail deleted Sucessfully!", "success");
    },
    onError: (error) => {
      let errorMessage = "An error occurred while adding hours.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }
      showToast(errorMessage, "error");
    },
  });
};

// get Job details

const getJobDetails = async (jobId, token) => {
  return axios.get(`${API_BASE_URL}/foreman/job/${jobId}`, {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

export const useGetJobDetails = (jobId, token) => {
  return useQuery(["job", jobId, token], () => getJobDetails(jobId, token));
};

//get worker job details

const getWorkerJobDetails = async (jobId, token) => {
  return axios.get(`${API_BASE_URL}/worker/job/${jobId}`, {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

export const useGetWorkerJobDetails = (jobId, token) => {
  return useQuery(["job", jobId, token], () =>
    getWorkerJobDetails(jobId, token)
  );
};

// get Entries
const getWeeklyDetails = async (w, y, token) => {
  return axios.get(
    `${API_BASE_URL}/foreman/weeklyDetails?week=${w}&year=${y}`,
    {
      headers: {
        authorization: "Bearer " + token,
      },
    }
  );
};

export const useGetWeeklyDetails = (week, year, token) => {
  return useQuery(["weeklyDetails", week, year, token], () =>
    getWeeklyDetails(week, year, token)
  );
};

// get Metrics
const getMetrics = async (s, e, token) => {
  return axios.get(
    `${API_BASE_URL}/foreman/foremanMetrics?startDate=${s}&endDate=${e}`,
    {
      headers: {
        authorization: "Bearer " + token,
      },
    }
  );
};

export const useGetMetrics = (s, e, token) => {
  return useQuery(["metrics", s, e, token], () => getMetrics(s, e, token));
};

// update job

export const updateJob = async (jobData) => {
  return axios.put(`${API_BASE_URL}/foreman/job/${jobData.jobId}`, jobData, {
    headers: {
      authorization: "Bearer " + jobData.token,
    },
  });
};

export const useUpdateJob = () => {
  const queryClient = useQueryClient();
  return useMutation(updateJob, {
    onSuccess: (data) => {
      queryClient.resetQueries("job");
      showToast("Job Updated Successfully!", "success");
    },
  });
};

// create job

export const createJob = async (jobData) => {
  return axios.post(`${API_BASE_URL}/foreman/job/create`, jobData, {
    headers: {
      authorization: "Bearer " + jobData.token,
    },
  });
};

export const useCreateJob = () => {
  const navigate = useNavigate();
  return useMutation(createJob, {
    onSuccess: (data) => {
      navigate("/jobs");
      showToast("New Job created Successfully!", "success");
    },
  });
};

// add Hours to worker

export const addHours = async (userData) => {
  return axios.put(
    `${API_BASE_URL}/foreman/job/${userData.jobId}/add-hours`,
    userData,
    {
      headers: {
        authorization: "Bearer " + userData.token,
      },
    }
  );
};

export const useAddHours = () => {
  const queryClient = useQueryClient();
  return useMutation(addHours, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("workerHours");
      showToast("Hours Added Successfully!", "success");
    },
    onError: (error) => {
      let errorMessage = "An error occurred while adding hours.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }
      showToast(errorMessage, "error");
    },
  });
};

// ------------------------------------------ Add Budget to job ----------------------------

export const addBudget = async (userData) => {
  return axios.put(
    `${API_BASE_URL}/foreman/job/${userData.jobId}/add-budget`,
    userData,
    {
      headers: {
        authorization: "Bearer " + userData.token,
      },
    }
  );
};

export const useAddBudget = () => {
  const queryClient = useQueryClient();
  return useMutation(addBudget, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("job");
      showToast("Budget Added Successfully!", "success");
    },
    onError: (error) => {
      let errorMessage = "An error occurred while adding hours.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }
      showToast(errorMessage, "error");
    },
  });
};

//----------------------------- update bonus

export const updateBonus = async (userData) => {
  return axios.put(`${API_BASE_URL}/foreman/updateBonusSplit`, userData, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useUpdateBonus = () => {
  const queryClient = useQueryClient();
  return useMutation(updateBonus, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("weeklyDetails");
      showToast("Bonus Updated Successfully!", "success");
    },
    onError: (error) => {
      let errorMessage = "An error occurred while updating bonus.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }
      showToast(errorMessage, "error");
    },
  });
};

//----------- add hours by worker

export const addWorkerHours = async (userData) => {
  return axios.put(
    `${API_BASE_URL}/worker/job/${userData.jobId}/add-hours`,
    userData,
    {
      headers: {
        authorization: "Bearer " + userData.token,
      },
    }
  );
};

export const useAddWorkerHours = () => {
  const queryClient = useQueryClient();
  return useMutation(addWorkerHours, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("job");
      showToast("Hours Added Successfully!", "success");
    },
    onError: (error) => {
      let errorMessage = "An error occurred while adding hours.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }
      showToast(errorMessage, "error");
    },
  });
};

// get worker Hours

const getWorkerHours = async (jobId, token) => {
  return axios.get(`${API_BASE_URL}/foreman/job/${jobId}/worker-hours`, {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

export const useGetWorkerHours = (jobId, token) => {
  return useQuery(["workerHours", jobId, token], () =>
    getWorkerHours(jobId, token)
  );
};

//--------------------- get NRH

const getNRHours = async (token) => {
  return axios.get(`${API_BASE_URL}/foreman/nrh`, {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

export const useGetNRHours = (token) => {
  return useQuery("NRH", () => getNRHours(token));
};

// -------------------------------------add Hours to worker

export const addNRHours = async (userData) => {
  return axios.post(`${API_BASE_URL}/foreman/nrh`, userData, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useAddNRHours = () => {
  const queryClient = useQueryClient();
  return useMutation(addNRHours, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("NRH");
      showToast("Non Revenue Hours Added Successfully!", "success");
    },
    onError: (error) => {
      let errorMessage = "An error occurred while adding hours.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }
      showToast(errorMessage, "error");
    },
  });
};

//----------------------------- delete NRH

export const deleteNRHour = async (userData) => {
  return axios.delete(`${API_BASE_URL}/foreman/nrh/${userData.hourId}`, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useDeleteNRHour = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteNRHour, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("NRH");
      showToast("Hour detail deleted Sucessfully!", "success");
    },
  });
};

// get sales person

const getSalesPerson = async (token) => {
  return axios.get(`${API_BASE_URL}/foreman/salespersons`, {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

export const useGetSalesPerson = (token) => {
  return useQuery(["salesPersons", token], () => getSalesPerson(token));
};

//download PDF
export const downloadPDF = async (w, y, token, note) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/foreman/pdf?week=${w}&year=${y}&note=${note}`,
      {
        headers: { authorization: "Bearer " + token },
        responseType: "blob", // Important
      }
    );

    const filename = `${moment().format("YYYYMMDDHHmmssSSS")}.pdf`;
    FileDownload(response.data, filename);
  } catch (error) {
    // Handle error here
    console.log(error);
  }
};

//download worker PDF
export const downloadWorkerPDF = async (w, y, token, note) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/worker/pdf?week=${w}&year=${y}&note=${note}`,
      {
        headers: { authorization: "Bearer " + token },
        responseType: "blob", // Important
      }
    );

    const filename = `${moment().format("YYYYMMDDHHmmssSSS")}.pdf`;
    FileDownload(response.data, filename);
  } catch (error) {
    // Handle error here
    console.log(error);
  }
};

//-------------------------------------- Worker NRH Functions ---------------------------
// ----------------delete NRH Worker

export const deleteWorkerNRH = async (userData) => {
  return axios.delete(`${API_BASE_URL}/worker/nrh/${userData.hourId}`, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useDeleteWorkerNRH = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteWorkerNRH, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("nrh");
      showToast("Hour detail deleted Sucessfully!", "success");
    },
    onError: (error) => {
      let errorMessage = "An error occurred while adding hours.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }
      showToast(errorMessage, "error");
    },
  });
};

//----------- add hours by worker

export const addWorkerNRH = async (userData) => {
  return axios.post(`${API_BASE_URL}/worker/nrh`, userData, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useAddWorkerNRH = () => {
  const queryClient = useQueryClient();
  return useMutation(addWorkerNRH, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("nrh");
      showToast("Hours Added Successfully!", "success");
    },
    onError: (error) => {
      let errorMessage = "An error occurred while adding hours.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }
      showToast(errorMessage, "error");
    },
  });
};

//------------get worker nrh details

const getWorkerNRH = async (token) => {
  return axios.get(`${API_BASE_URL}/worker/nrh`, {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

export const useGetWorkerNRH = (token) => {
  return useQuery(["nrh", token], () => getWorkerNRH(token));
};

// get worker dashbaord details
const getWorkerDashboard = async (w, y, token) => {
  return axios.get(`${API_BASE_URL}/worker/weeklyDetails?week=${w}&year=${y}`, {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

export const useGetWorkerDashboard = (week, year, token) => {
  return useQuery(["workerDashboard", week, year, token], () =>
    getWorkerDashboard(week, year, token)
  );
};

// get job types

const getJobTypes = async (token) => {
  return axios.get(`${API_BASE_URL}/foreman/jobtypes`, {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

export const useGetJobTypes = (token) => {
  return useQuery(["jobtypes", token], () => getJobTypes(token));
};
