import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { API_BASE_URL } from "../config";
import showToast from "../components/Toast";

// Fetch organization details by ID
const getOrganizationById = async (organizationId, token) => {
  const response = await axios.get(
    `${API_BASE_URL}/foreman/organizations/${organizationId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const useGetOrganizationById = (organizationId, token) => {
  return useQuery(
    ["organization", organizationId],
    () => getOrganizationById(organizationId, token),
    {
      enabled: !!organizationId, // Only fetch if organizationId exists
      onError: (error) => {
        console.error("Error fetching organization details:", error.message);
        showToast("Failed to fetch organization details!", "error");
      },
    }
  );
};

// Update organization details
const updateOrganization = async (organizationId, data, token) => {
  const response = await axios.put(
    `${API_BASE_URL}/organizations/${organizationId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const useUpdateOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ organizationId, data, token }) =>
      updateOrganization(organizationId, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("organization");
        showToast("Organization updated successfully!", "success");
      },
      onError: (error) => {
        console.error("Error updating organization:", error.message);
        showToast("Failed to update organization!", "error");
      },
    }
  );
};

// Fetch all organizations (if applicable)
const getAllOrganizations = async (token) => {
  const response = await axios.get(`${API_BASE_URL}/organizations`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const useGetAllOrganizations = (token) => {
  return useQuery("organizations", () => getAllOrganizations(token), {
    enabled: !!token, // Only fetch if token exists
    onError: (error) => {
      console.error("Error fetching organizations:", error.message);
      showToast("Failed to fetch organizations!", "error");
    },
  });
};

// Sync organization with external system (e.g., Salesforce)
const syncOrganization = async (organizationId, token) => {
  const response = await axios.post(
    `${API_BASE_URL}/organizations/${organizationId}/sync`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
