import React, { useEffect, useRef, useState } from "react";
import {
  ChevronLeft,
  ChevronRight,
  ArrowBackIos,
  Close,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import moment from "moment";
import { useSelector } from "react-redux";
import useOutsideClick from "../hooks/useOutsideClick";
import { useDeleteJob, useGetJobs } from "../apiCalls/jobApiCalls";
import { useGetOrganizationById } from "../apiCalls/organizationApiCalls";
import WeekSelector from "../components/WeekSelector";
import { formatted } from "../utils";

export default function Jobs() {
  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;
  const userId = currentUser.user._id;
  const organizationId = currentUser.user.organization;
  const userFilterRef = useRef();
  const [openFilter, setOpenFilter] = useState(false);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("-addedOn");
  const [limit, setLimit] = useState("5");
  const [page, setPage] = useState(1);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [week, setWeek] = useState(moment().isoWeek());
  const [year, setYear] = useState(moment().isoWeekYear());
  const [syncRemoteJobs, setSyncRemoteJobs] = useState(false);

  const handleSyncFromSalesforce = async () => {
    if (organization?.data?.salesforceSyncEnabled) {
      console.log("Syncing jobs from Salesforce...");
      setSyncRemoteJobs(true);
    }
  };

  function handleWeekChange({ week, year }) {
    setWeek(week);
    setYear(year);
  }

  useOutsideClick(userFilterRef, () => {
    if (openFilter) setOpenFilter(false);
  });

  const {
    data: organization,
  } = useGetOrganizationById(organizationId, token);

  const { isLoading: isJobsLoading, data: jobs } = useGetJobs(
    token,
    search,
    sort,
    limit,
    page,
    week,
    year,
    syncRemoteJobs,
  );

  const { mutate: deleteJobMutate, isLoading: isDeleteJobLoading } =
    useDeleteJob();

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handleSortChange = (e) => {
    setSort(e.target.value);
    setOpenFilter(false);
    setPage(1);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setOpenFilter(false);
    setPage(1);
  };

  const handleClearFilters = () => {
    setSearch("");
    setLimit("");
    setSort("");
    setOpenFilter(false);
    setPage(1);
  };

  const handleDelete = (jobId) => {
    setConfirmationPopup(true);
    setDeleteId(jobId);
  };

  const handleCancelDelete = () => {
    setConfirmationPopup(false);
  };

  const handleConfirmDelete = () => {
    const data = {
      userId: deleteId,
      token: token,
    };
    setConfirmationPopup(false);
    deleteJobMutate(data);
  };

  return (
    <>
      <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 md:mt-24 min-h-screen">
        <WeekSelector week={week} year={year} onWeekChange={handleWeekChange} />

        <div className="flex justify-between gap-3 md:gap-5 lg:gap-8 xl:gap-10 pt-2">
          <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
            <button
              onClick={() => window.history.back()}
              className="w-full h-full rounded-full flex items-center justify-center"
            >
              <ArrowBackIos
                style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
              />
            </button>
          </div>
          <div
            ref={userFilterRef}
            className={`${
              openFilter ? "" : "hidden"
            } filter-sideBar w-2/3 max-w-xs md:w-1/3 flex flex-col gap-3 rounded-lg bg-white shadow px-3 py-5 absolute z-40`}
          >
            <div className="close absolute z-20 right-2 top-2">
              <Close onClick={() => setOpenFilter(false)} />
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-lg font-medium" htmlFor="">
                Sort
              </label>
              <select
                className="outline-none px-1 py-1"
                value={sort}
                onChange={handleSortChange}
              >
                <option value="">Default Sort</option>
                <option value="title">Name A-Z</option>
                <option value="-title">Name Z-A</option>
                <option value="-addedOn">Most Recent</option>
                <option value="addedOn">Least Recent</option>
              </select>
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-lg font-medium" htmlFor="">
                Limit
              </label>
              <select
                className="outline-none px-1 py-1"
                value={limit}
                onChange={handleLimitChange}
              >
                <option value="">Default Limit</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <button
              onClick={handleClearFilters}
              className="mt-1 px-4 py-2 font-base tracking-wide hover:scale-105 transition-all bg-white btn-shadow hover:shadow-custom hover:bg-stone-50 border border-gray-100 rounded-lg"
              style={{ height: '70px'}}
            >
              Clear
            </button>
          </div>
          <div>
            <button
              onClick={() => setOpenFilter(!openFilter)}
              className={`${
                openFilter ? "hidden" : ""
              } px-4 md:px-8 py-2 font-base tracking-wide hover:scale-105 transition-all bg-white btn-shadow hover:shadow-custom hover:bg-stone-50 border border-gray-100 rounded-lg`}
              style={{ height: '70px'}}
            >
              Filter
            </button>
          </div>
          <div className="flex px-2 py-2 w-full rounded-lg btn-shadow hover:scale-105 transition-all bg-white items-center">
            <input
              type="text"
              className="px-1 w-full bg-white outline-none"
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
            />

            <div className="text-gray-500">
              <Close className="text-gray-400" onClick={() => setSearch("")} />
            </div>
          </div>
          <Link to="/PostJob">
            <button 
              className="px-4 md:px-8 py-2 font-base tracking-wide hover:scale-105 transition-all bg-white btn-shadow hover:shadow-custom hover:bg-stone-50 border border-gray-100 rounded-lg"
              style={{ height: '70px'}}
            >
              Create
            </button>
          </Link>
          {/* Conditionally render the "Sync from Salesforce" button */}
            {organization?.data?.salesforceSyncEnabled && (
              <button
                onClick={handleSyncFromSalesforce}
                disabled={isJobsLoading}
                className="px-4 md:px-8 py-2 font-base tracking-wide hover:scale-105 transition-all bg-white btn-shadow hover:shadow-custom hover:bg-stone-50 border border-gray-100 rounded-lg"
                style={{ height: '70px'}}
              >
                {isJobsLoading ? "Syncing..." : "Sync Jobs"}
              </button>
          )}

        </div>

        {isJobsLoading ? (
          <Loader />
        ) : (
          <>
            <div className="space-y-5 md:space-y-0 pt-8 pb-20 md:flex md:flex-wrap md:justify-between md:gap-5">
              {jobs.data.data.map((job) => (
                <div
                  key={job._id}
                  className="card hover:scale-105 transition-all ease-in-out bg-white card-shadow rounded-lg p-3 lg:p-4 md:w-full lg:w-5/12 xl:w-5/12"
                >
                  <div className="pb-4">
                    <Link to={`/addHours/${job._id}`}>
                      <div className="font-bold text-lg">{job.title}</div>
                      <div className="text-sm text-gray-400">
                        {moment(job.addedOn).format("dddd MMM DD, YYYY")}
                      </div>
                      {job.typeTitle && (
                        <div className="text-md font-medium tracking-wide">
                          {job.typeTitle}
                        </div>
                      )}
                      <div>
                        <div>
                          <div className="flex justify-between">
                            <div>
                              {(() => {
                                if (!job.jobType) {
                                  return `${formatted(job.amount)} @ ${
                                    job.commissionRate
                                  }%`;
                                } else {
                                  if (job.jobType === "Percentage") {
                                    return `${formatted(job.amount)} @ ${
                                      job.commissionRate
                                    }%`;
                                  } else {
                                    return `${formatted(job.amount)} ${
                                      job.jobType
                                    } @ $${job.commissionRate / 100}`;
                                  }
                                }
                              })()}
                            </div>
                            <div>
                              {formatted(
                                (job.amount * job.commissionRate) / 100
                              )}
                            </div>
                          </div>
                          {job.totalAdditionalBudget && (
                            <div className="flex justify-between">
                              <div>Additional Budget:</div>
                              <div>
                                {formatted(job.totalAdditionalBudget || 0)}
                              </div>
                            </div>
                          )}
                          <div className="flex justify-between">
                            <div>Total Budget:</div>
                            <div className="font-bold text-[#59c3c2]">
                              {formatted(
                                (job.amount * job.commissionRate) / 100 +
                                  (job.totalAdditionalBudget || 0)
                              )}
                            </div>
                          </div>
                          <div className="flex justify-between">
                            <div>Total Labor:</div>
                            <div className="font-bold">
                              {(job.totalHours || 0)} Hrs
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  {job.foremanId === userId && (
                    <div className={`gap-2 text-white flex justify-end`}>
                      <Link to={`/editJob/${job._id}`}>
                        <div className="flex items-center h-full text-center px-4 lg:px-4 py-2 text-xs font-medium tracking-wide hover:scale-105 transition-al bg-[#59c3c2] hover:bg-teal-600 border border-gray-100 rounded-lg">
                          Edit
                        </div>
                      </Link>
                      <Link to={`/addHours/${job._id}`}>
                        <div className="flex  text-center px-4 lg:px-4 py-2 text-xs items-center font-medium tracking-wide hover:scale-105 transition-al bg-[#59c3c2] hover:bg-teal-600 border border-gray-100 rounded-lg">
                          Add Hours
                        </div>
                      </Link>
                      <Link to={`/addBudget/${job._id}`}>
                        <div className="flex  text-center px-4 lg:px-4 py-2 text-xs items-center font-medium tracking-wide hover:scale-105 transition-al bg-[#59c3c2] hover:bg-teal-600 border border-gray-100 rounded-lg">
                          Add Budget
                        </div>
                      </Link>
                      <button
                        onClick={() => handleDelete(job._id)}
                        className="flex items-center  text-center px-4 lg:px-4 py-2 text-xs font-medium tracking-wide hover:scale-105 transition-al bg-gradient-to-b from-[#cc3636] to-[#941d1d] hover:bg-stone-50 border border-gray-100 rounded-lg"
                      >
                        {isDeleteJobLoading ? "...Is Deleting" : "Delete"}
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        )}

        <div
          className={`${
            confirmationPopup ? "" : "hidden"
          } fixed inset-0 flex items-center justify-center z-50`}
        >
          <div className="bg-white rounded-lg shadow-lg px-8 py-6 relative">
            <div className="flex justify-end">
              <button className=" text-gray-500 hover:text-gray-700 absolute right-2 top-2">
                <Close
                  onClick={() => setConfirmationPopup(false)}
                  style={{ fontSize: "28" }}
                  className="h-5 w-5"
                />
              </button>
            </div>

            <p className="text-lg mb-4 pt-4">
              Are you sure you want to delete?
            </p>
            <div className="flex justify-between">
              <button
                onClick={handleConfirmDelete}
                className="border border-[#cc3636] hover:bg-gradient-to-b hover:text-white from-[#cc3636] to-[#941d1d]  text-gray-800 font-medium py-2 px-4 rounded mr-2"
              >
                Confirm
              </button>
              <button
                onClick={handleCancelDelete}
                className="border border-gray-400 hover:border-gray-300 hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>

        <div className="wrapper fixed bottom-0 left-0 right-0 w-full bg-white mt-20">
          <div className="pages  flex gap-5 items-center justify-center  py-3">
            {jobs?.data.pagination.previous && (
              <div
                className="left"
                onClick={() => setPage(jobs?.data.pagination.previous?.page)}
              >
                <div className="w-8 h-8 hover:shadow hover:scale-110 transition-all ease-in-out border border-gray-200 rounded-full flex items-center justify-center hover:text-white hover:bg-[#59c3c2]">
                  <ChevronLeft style={{ fontSize: "24" }} />
                </div>
              </div>
            )}
            <div className="numbers flex gap-2">
              <p className="hover:scale-125 transition-all ease-in-out hover:font-bold">
                {page}
              </p>
            </div>
            {jobs?.data.pagination.next && (
              <div
                className="right"
                onClick={() => setPage(jobs?.data.pagination.next?.page)}
              >
                <div className="w-8 h-8 hover:shadow hover:scale-110 transition-all ease-in-out border border-gray-200 rounded-full flex items-center justify-center hover:text-white hover:bg-[#59c3c2]">
                  <ChevronRight style={{ fontSize: "24" }} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
