import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  downloadPDF,
  useGetJobDetails,
  useGetWorkerHours,
} from "../apiCalls/jobApiCalls";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import normalDate from "../utils";
import {
  KeyboardArrowRight,
  ExpandMore,
  ArrowBackIos,
} from "@mui/icons-material";

import moment from "moment";

export default function JobDetails() {
  const [workerHoursDetailsVisibility, setWorkerHoursDetailsVisibility] =
    useState({});

  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;
  const { jobId } = useParams();

  const { isLoading: isJobDetailsLoading, data: jobDetails } = useGetJobDetails(
    jobId,
    token
  );

  const [totalLaborCost, setTotalLaborCost] = useState(0);

  useEffect(() => {
    let laborCost = 0;

    if (jobDetails && jobDetails.data.data.noOfHours) {
      jobDetails.data.data.noOfHours.forEach((worker) => {
        let hours = worker.hours;
        let hourlyRate = worker.currentHourlyRate;
        let overtimeRate = worker.currentOvertimeRate;

        if (hours > 8) {
          let normalHours = 8;
          let overtimeHours = hours - 8;

          let normalCost = normalHours * hourlyRate;
          let overtimeCost = overtimeHours * overtimeRate;

          laborCost += normalCost + overtimeCost;
        } else {
          laborCost += hours * hourlyRate;
        }
      });

      setTotalLaborCost(laborCost);
    }
  }, [jobDetails]);

  const { isLoading: isWorkerHoursLoading, data: workerHours } =
    useGetWorkerHours(jobId, token);

  const fallbackImage = "/images/avatar.jpg";

  function handleWorkerHoursDetails(workerId) {
    setWorkerHoursDetailsVisibility((prevState) => ({
      ...prevState,
      [workerId]: !prevState[workerId],
    }));
  }

  return (
    <>
      {isJobDetailsLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex w-full items-center justify-center">
            <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:w-2/5">
              <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
                <button
                  onClick={() => window.history.back()}
                  className="w-full h-full rounded-full flex items-center justify-center"
                >
                  <ArrowBackIos
                    style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
                  />
                </button>
              </div>
              <div className="flex justify-between align-middle py-4">
                <h1 className="text-2xl font-bold">Job Details</h1>
                <button
                  id="print-button"
                  className="px-4 py-1 text-sm font-medium bg-gray-200 rounded-md hover:bg-gray-300 cursor-pointer"
                  onClick={() => downloadPDF(jobId, token)}
                >
                  Print PDF
                </button>
              </div>

              {isJobDetailsLoading ? (
                <Loader />
              ) : (
                <>
                  <div className="flex items-center justify-between px-6 border border-gray-100 btn-shadow py-3 rounded-xl">
                    <div className="">
                      <p className="text-xl">
                        <b>{jobDetails.data.data.title}</b>
                      </p>
                      <p className="">
                        Started on:{" "}
                        <b>
                          {moment(jobDetails.data.data.addedOn).format(
                            "MMM DD, YYYY"
                          )}
                        </b>
                      </p>
                      <p className="">
                        Amount: <b>${jobDetails.data.data.amount}</b>
                      </p>
                      <p className="">
                        Rate: <b>{jobDetails.data.data.commissionRate}%</b>
                      </p>
                      <p className="">
                        Budget:{" "}
                        <b>
                          $
                          {(
                            (jobDetails.data.data.commissionRate *
                              jobDetails.data.data.amount) /
                            100
                          ).toFixed(2)}
                        </b>
                      </p>
                      <p className="">
                        Labor used: <b>${totalLaborCost}</b>
                      </p>
                    </div>
                  </div>
                </>
              )}

              {isWorkerHoursLoading ? (
                <Loader />
              ) : (
                <div className="space-y-3 pb-8 pt-10">
                  <h1 className="text-2xl font-bold pb-4">Workers Hours</h1>

                  {workerHours.data.data.map((worker) => (
                    <div key={worker.id}>
                      <div
                        onClick={() => handleWorkerHoursDetails(worker.id)}
                        className="card flex items-center gap-4 bg-white card-shadow rounded-lg p-4"
                      >
                        <div
                          className="w-16 h-16 border border-gray-200  rounded-full"
                          style={{
                            backgroundImage: `url("${worker.workerImg}"), url("${fallbackImage}")`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                        <div className="grow">
                          <h2 className="text-lg font-medium tracking-wide">
                            {worker.workerName}
                          </h2>
                        </div>
                        <div className="text-4xl">
                          {workerHoursDetailsVisibility[worker.id] ? (
                            <ExpandMore
                              style={{ fontSize: "44", color: "#d8d8d8" }}
                            />
                          ) : (
                            <KeyboardArrowRight
                              style={{ fontSize: "44", color: "#d8d8d8" }}
                            />
                          )}
                        </div>
                      </div>

                      <div
                        className={`card flex flex-col bg-white card-shadow rounded-lg p-4 overflow-x-auto ${
                          workerHoursDetailsVisibility[worker.id]
                            ? ""
                            : "hidden"
                        }`}
                      >
                        <table className="w-full min-w-max">
                          <thead>
                            <tr>
                              <th className="text-left">Date</th>
                              <th className="text-center">Hours</th>
                              <th className="text-center">Pay</th>
                              <th className="text-center">Overtime</th>
                            </tr>
                          </thead>
                          <tbody>
                            {worker.hourlyDetails.map((hourlyDetail, index) => (
                              <tr key={index}>
                                <td className="border-t border-gray-200">
                                  {moment(hourlyDetail.date).format(
                                    "MMM DD, YYYY"
                                  )}
                                </td>
                                <td className="text-center border-t border-gray-200">
                                  {hourlyDetail.hours}
                                </td>
                                <td className="text-center border-t border-gray-200">
                                  {hourlyDetail.normalPay}$
                                </td>
                                <td className="text-center border-t border-gray-200">
                                  {hourlyDetail.overtimePay}$
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
